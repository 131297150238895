import React from 'react';

const IconLogo = () => (
  <svg
    id="logo"
    width="256px"
    height="312px"
    viewBox="0 0 256 312"
    preserveAspectRatio="xMidYMid">
    <title>Nicolas</title>
    <g>
      <path
        d="M181.251596,154.622337 C181.425452,153.583221 181.52023,152.532414 181.535117,151.47896 L181.535117,249.67557 L130.082361,202.623541 L104.195733,179.017402 L88.6144483,164.693468 L81.6866935,158.369734 L79.1350116,156.039938 L78.9501071,155.879687 C78.3214319,155.374281 77.7174105,154.844222 77.1380431,154.36347 C76.5586757,153.882719 76.0532701,153.463602 75.3629601,153.67316 C74.8708016,153.853293 74.5039451,154.271231 74.3891298,154.782587 L74.3891298,62.3303434 L74.9191893,62.8110951 L85.483399,72.3644936 C91.0428606,77.2952799 96.65163,82.2260662 102.198765,87.1568526 C110.088023,94.224313 117.952627,101.332863 125.792577,108.482503 C131.99715,114.120036 138.193505,119.765786 144.381642,125.419754 C151.777821,132.162605 159.075385,138.979417 166.471564,145.672959 C169.799845,148.680739 173.15278,151.626884 176.518042,154.585356 C177.233006,155.214031 177.935643,155.818052 178.662934,156.459054 C179.162731,156.822432 179.813247,156.906069 180.388709,156.68094 C180.730355,156.491769 180.979766,156.171097 181.079019,155.793398 C181.177635,155.324974 181.177635,155.004472 181.251596,154.622337 Z"
        fill="#64FFDA"></path>
      <path
        d="M74.2781871,155.324974 C74.1384892,156.445072 74.0972415,157.57526 74.1549174,158.702562 C74.1549174,190.136325 74.1549174,221.570088 74.3891298,252.991524 L74.3891298,264.184409 C74.115361,269.586537 71.4664634,274.591013 67.1532008,277.855014 C58.7585371,284.869057 50.9309138,292.53643 42.8320972,299.907956 C39.4421816,302.989697 36.07692,306.071439 32.5883886,309.079218 C29.0998573,312.086998 20.1258262,312.456807 15.158059,308.302619 C11.3781185,305.021914 7.85847549,301.452815 4.63083016,297.627467 C1.78274695,294.777451 0.136955145,290.943154 0.0328719089,286.915334 C0.0328719089,286.027792 -0.0410898861,285.053962 0.0328719089,283.981516 C0.0328719089,282.132471 0.0328719089,280.283426 0.094506738,278.422054 C0.156141567,273.121459 0.094506738,267.808537 0.094506738,262.507941 L0.094506738,89.6345727 C0.0838117467,88.3133386 0.241216457,86.9961097 0.562931439,85.7145976 C0.889976796,84.5998882 1.30208564,83.5119209 1.79562802,82.4602786 C2.48712304,80.9152896 3.32072412,79.4379174 4.28567512,78.0472248 L24.0088204,58.3240795 L36.5699986,45.7752283 C37.2383135,45.0997675 38.0025897,44.5265603 38.8381603,44.074107 C40.0556788,43.3892748 41.4040692,42.9692155 42.7951164,42.8414104 L42.7951164,42.8414104 C46.9205324,42.0814833 51.181331,42.8669446 54.7646002,45.0479373 L54.7646002,45.0479373 C55.6835706,45.6087661 56.5463393,46.2568746 57.340936,46.983271 L74.3891298,62.2933625 L74.3891298,154.745606 C74.338507,154.935865 74.3014332,155.129473 74.2781871,155.324974 Z"
        fill="#64FFDA"></path>
      <path
        d="M253.401327,230.778331 L217.172375,264.061139 C217.030268,264.168213 216.882098,264.266993 216.728604,264.356986 L216.593007,264.455602 L216.346468,264.603526 C209.776302,268.606473 201.349901,267.776905 195.686473,262.569576 L193.504601,260.658897 L181.485809,249.67557 L181.485809,46.4532114 C181.68304,44.7397632 181.978887,43.0263149 182.311716,41.3498476 C183.174603,36.9737747 187.020616,34.8042287 189.954434,32.0676423 C196.327476,26.1506987 202.897748,20.4556405 209.394059,14.6619666 C213.868748,10.6680296 218.33111,6.66176574 222.842779,2.69248275 C226.775081,-0.771394647 234.393146,-0.598817126 238.091236,1.54607493 C239.028683,2.09314605 239.890646,2.76033841 240.655245,3.53071643 L244.821759,7.54930728 L248.187021,10.7666454 L251.256435,13.7374441 C252.088512,14.5051168 252.825206,15.3701111 253.450635,16.31378 C254.906183,18.7089859 255.634237,21.4755925 255.54622,24.2769999 C255.484585,42.3483318 255.620181,60.4196637 255.657162,78.5156495 C255.731124,109.209794 255.796868,139.903939 255.854394,170.598084 C255.854394,186.327293 255.883157,202.052392 255.940682,217.773382 C255.940682,218.500673 255.940682,219.215637 255.940682,219.930601 C256.125587,224.614848 256.026971,227.708917 253.401327,230.778331 Z"
        fill="#64FFDA"></path>
    </g>
  </svg>
);

export default IconLogo;
